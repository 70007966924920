<template>
  <div>
    <v-main>
      <v-form :disabled="isComponent">
        <v-row gutters="2" class="pt-0 pb-0" justify="end" v-if="!isComponent">
          <v-col cols="12" md="12" class="text-right">
            <Buttons
              :textCancel="$t('GENERAL.CANCEL')"
              v-on:cancel="btnCancel"
              :textSave="$t('GENERAL.SAVE')"
              v-on:save="saveData"
              :save_loading="save_loading"
              :btnSaveNew="false"
              :save_new_loading="save_new_loading"
              :textSaveNew="$t('GENERAL.SAVE_AND_NEW')"
              v-on:save-new="btnSaveNew"
              :btnDuplicate="isEditRoom"
              :duplicate_loading="duplicate_loading"
              :textDuplicate="$t('GENERAL.DUPLICATE')"
              v-on:duplicate="duplicate"
              :btnDelete="isEditRoom"
              :delete_loading="delete_loading"
              :textDelete="$t('GENERAL.DELETE')"
              v-on:delete="btnDelete"
            />
          </v-col>
        </v-row>
        <v-row gutters="2" class="pt-0 pb-0">
          <v-col cols="12" md="8" class="pt-0 pb-0">
            <!-- Principal Form -->
            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PRODUCTS_ADDONS.GENERAL_DESCRIPTION") }}
              </v-card-title>
              <v-card-text>
                <FormRoom
                  ref="formRoom"
                  v-on:validate-category="validateApartment"
                  :gallery="filesGallery"
                  :startDateTemplate="dataStartDateTemplate"
                  @loadTranslation="loadTranslations"
                  :isComponent="isComponent"
                ></FormRoom>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-text>
                <Gallery
                  :key="galleryKey"
                  :title="$t('PRODUCTS_ADDONS.UPLOAD_IMAGES')"
                  :showInfo="true"
                  ref="gallery"
                  :infoText1="$t('PRODUCTS_ADDONS.MAX_6_FILES')"
                  :files="dataGallery"
                  @data="filesGallery = $event"
                  entity="room"
                  :isOnlyShow="isComponent"
                ></Gallery>
                <v-text-field
                  v-model="featuredUrl"
                  :label="$t('CAMPUS.VIDEO')"
                  outlined
                  dense
                  :disabled="isComponent"
                />
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>{{ $t("PRODUCTS_ADDONS.ROOM_INFO") }}</v-card-title>
              <v-card-text>
                <FormRoomInfo
                  ref="formRoomInfo"
                  :isOnlyShow="isComponent"
                ></FormRoomInfo>
              </v-card-text>
            </v-card>
            <v-card class="mb-2" v-if="show.showApartment">
              <v-card-title>{{ $t("PRODUCTS_ADDONS.APARTMENT") }}</v-card-title>
              <v-card-text>
                <FormApartment
                  ref="formApartment"
                  :isOnlyShow="isComponent"
                ></FormApartment>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>{{ $t("PRODUCTS_ADDONS.BEDS") }}</v-card-title>
              <v-card-text>
                <FormBed ref="formBed" :isOnlyShow="isComponent"></FormBed>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.START_DATE_AVAILABILITY")
              }}</v-card-title>
              <v-card-text>
                <v-radio-group v-model="startDate.typeStartDate" row>
                  <v-radio
                    :label="$t('PRODUCTS_ADDONS.LODGING_CALENDAR')"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    :label="$t('PRODUCTS_ADDONS.CUSTOM_START_DATES')"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
                <StartDateTemplate
                  v-show="startDate.typeStartDate === SHOW_CUSTOM_START_DATE"
                  ref="StartDateTemplateForm"
                  :startDateTemplate="dataStartDateTemplate"
                  v-on:save_start_date_template="
                    save_course_start_dates($event)
                  "
                ></StartDateTemplate>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.PRICING_TEMPLATE")
              }}</v-card-title>
              <v-card-text>
                <ValidationObserver ref="observerValidator">
                  <v-row class="pb-0 mb-0 pt-0 mt-0">
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('PRODUCTS_ADDONS.TYPE_TARIFF')"
                        rules="required"
                      >
                        <v-radio-group
                          v-model="form.typeTariffTemplate"
                          row
                          required
                          :error-messages="errors"
                        >
                          <v-radio
                            :label="
                              $t('PRODUCTS_ADDONS.SELECT_TARIFF_TEMPLATE')
                            "
                            :value="1"
                          ></v-radio>
                          <v-radio
                            :label="$t('PRODUCTS_ADDONS.CREATE_NEW_TARIFF')"
                            :value="2"
                          ></v-radio>
                        </v-radio-group>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row v-if="form.typeTariffTemplate === 1">
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                        rules="required"
                      >
                        <v-autocomplete
                          :items="productPricings"
                          item-text="name"
                          item-value="productPricingTemplateId"
                          v-model="productPricingTemplateId"
                          dense
                          outlined
                          :error-messages="errors"
                          :label="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      form.typeTariffTemplate === 1 &&
                        productPricingTemplateId > 0
                    "
                  >
                    <v-col cols="12">
                      <PricingTemplate
                        :showMessages="false"
                        :productPricingTemplateId="productPricingTemplateId"
                        :availableOptions="[1, 2, 3, 4]"
                        ref="pricingTemplateView"
                        :onlyView="true"
                      ></PricingTemplate>
                    </v-col>
                  </v-row>
                  <v-row v-if="form.typeTariffTemplate === 2">
                    <v-col cols="12">
                      <PricingTemplate
                        :showMessages="false"
                        :productPricingTemplateId="productPricingTemplateId"
                        :availableOptions="[1, 2, 3, 4]"
                        ref="pricingTemplate"
                        :onlyView="isComponent"
                      ></PricingTemplate>
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>{{ $t("PRODUCTS_ADDONS.FEES") }}</v-card-title>
              <v-card-text>
                <em class="font-size-lg">
                  {{ $t("PRODUCTS_ADDONS.FEE_MESSAGE_ROOM") }}
                </em>
                <br />
                <ProductsTreeView
                  :input.sync="fees"
                  :selection="fees"
                  :data="listAllFees"
                  :name="$t('PRODUCTS_ADDONS.FEES')"
                  keyId="feeId"
                  keyName="name_with_price"
                  :isOnlyShow="isComponent"
                />
                <FeeModal :type="applicableTo" v-if="!isComponent"></FeeModal>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.MAINTENANCE_RATES")
              }}</v-card-title>
              <v-card-text>
                <FormMaintenanceRate
                  ref="formMaintenanceRate"
                  :isOnlyShow="isComponent"
                ></FormMaintenanceRate>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Additional Info -->
          <v-col cols="12" md="4" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.VISIBILITY")
              }}</v-card-title>
              <v-card-text>
                <Visibility
                  :value="isReady"
                  :input.sync="isReady"
                  :isPublished="isPublish"
                ></Visibility>
              </v-card-text>
            </v-card>

            <Translation
              ref="translationComponent"
              :fields="fieldsTranslate"
              model="product"
              skeleton="3"
              :isOnlyShow="isComponent"
            ></Translation>
          </v-col>

          <v-dialog v-model="isShowDialogDelete" max-width="350">
            <v-card>
              <v-card-title class="headline">
                {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="isShowDialogDelete = false"
                >
                  {{ $t("MESSAGE.DELETE.MODAL_NO") }}
                </v-btn>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="
                    deleteData();
                    isShowDialogDelete = false;
                  "
                >
                  {{ $t("MESSAGE.DELETE.MODAL_YES") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
    </v-main>
  </div>
</template>

<script>
import Vue from "vue";
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ValidationObserver, ValidationProvider } from "vee-validate";

// Import components
import FormRoom from "@/view/content/components/products_addons/room/Form";
import Gallery from "@/view/content/components/images/Gallery";
import FormRoomInfo from "@/view/content/components/products_addons/room_info/Form";
import FormBed from "@/view/content/components/products_addons/bed/Form";
import FormMaintenanceRate from "@/view/content/components/products_addons/maintenance_rate/Form";
import FormApartment from "@/view/content/components/products_addons/apartment/Form";
import StartDateTemplate from "@/view/content/components/forms/StartDateTemplate";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import Translation from "@/view/content/components/translation/Index";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import Buttons from "@/view/content/components/Buttons";
import FeeModal from "@/view/content/components/FeeModal";
import Visibility from "@/view/content/components/forms/Visibility";
import { mapActions, mapGetters } from "vuex";

const APPLICABLE_TO_LODGINGS = 2;

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FormRoom,
    Gallery,
    FormRoomInfo,
    FormBed,
    FormMaintenanceRate,
    FormApartment,
    StartDateTemplate,
    ProductsTreeView,
    Translation,
    PricingTemplate,
    Buttons,
    FeeModal,
    Visibility,
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    propProductId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tab: "",
      isShowDialogDelete: false,
      isVisibleForm: true,
      isShowDialogNewFee: false,
      show: {
        showUploadImages: false,
        showTemporalSettings: false,
        showAdditionalSettings: false,
        showRoomInfo: false,
        showBeds: false,
        showStartDateAvailability: false,
        showPricingTemplate: false,
        showMaintenanceRate: false,
        showApartment: false,
        showTranslations: false,
        showFee: false,
      },
      isEditRoom: false,
      dataGallery: null,
      dataStartDateTemplate: {},
      fees: [],
      translations: [],
      form: {
        typeTariffTemplate: null,
      },
      featuredUrl: null,
      fieldsTranslate: [
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.NAME"),
          type: "name",
        },
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.DESCRIPTION"),
          type: "description",
        },
      ],
      startDate: {
        typeStartDate: 0,
      },
      SHOW_CUSTOM_START_DATE: 1,
      nameOperation: this.$route.params.operation,
      applicableTo: APPLICABLE_TO_LODGINGS,
      galleryKey: 0,
      isReady: false,
      isPublish: false,
    };
  },
  created() {
    if (!this.isComponent) {
      this.isVisibleForm = true;
      if (this.$route.params.operation === "edit") {
        this.isEditRoom = true;
        this.fetchData();
      } else {
        this.isEditRoom = false;
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
          },
          {
            title: `${this.$i18n.t("PRODUCTS_ADDONS.ACCOMMODATION")}`,
            route: "/lodging",
          },
          {
            title: `${this.$i18n.t("PRODUCTS_ADDONS.NEW")}`,
          },
        ]);
      }

      this.getListFees();
    }
    this.getAllProductPricings();
    this.show.showUploadImages = true;
    this.show.showTemporalSettings = false;
    this.show.showAdditionalSettings = false;
    this.show.showRoomInfo = true;
    this.show.showBeds = true;
    this.show.showStartDateAvailability = true;
    this.show.showPricingTemplate = true;
    this.show.showMaintenanceRate = true;
    this.show.showApartment = false;
    this.show.showTranslations = true;
    this.show.showFee = true;
  },
  mounted() {
    if (this.isComponent && this.propProductId !== 0) {
      this.isEditRoom = true;
      this.getRoom(this.propProductId).then(data => {
        this.dataGallery = data.photos;
        this.dataStartDateTemplate = data.start_date_template;
        this.galleryKey += 1;
        this.loadTranslations(data);
      });
    }
  },
  computed: {
    ...mapGetters([
      "room",
      "accommodations",
      "getFees",
      "accommodation",
      "fee",
      "productPricings",
    ]),
    listAllFees() {
      return this.getFees.filter(item => {
        item.name_with_price = `${item.name} (${item.price})`;
        return item.applicableTo === 2;
      });
    },
    pricingProducts() {
      return this.productPricings?.filter(item => {
        return item.isReady === 1;
      });
    },
  },
  methods: {
    ...mapActions([
      "getRoom",
      "getListFees",
      "storeFee",
      "getAllProductPricings",
    ]),
    save_course_start_dates(startDateTemplate) {
      this.dataStartDateTemplate = {
        ...startDateTemplate,
      };
    },
    async saveData() {
      const resultValidatePricing = await this.$refs.observerValidator.validate();
      const validateStartDate =
        this.startDate.typeStartDate !== 0 &&
        !(await this.$refs.StartDateTemplateForm.validate());
      const resultValidateMeal = await this.$refs.formMaintenanceRate.validateFields();
      const resultFormBasic = await this.$refs.formRoom.validateForm();
      if (
        !resultFormBasic ||
        !resultValidateMeal ||
        validateStartDate ||
        !resultValidatePricing
      ) {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return false;
      }
      this.$refs.formRoomInfo.storeForm();
      this.$refs.formBed.storeForm();
      this.$refs.formMaintenanceRate.storeForm();
      if (this.show.showApartment) {
        this.$refs.formApartment.storeForm();
      }
      let visibilityIsReady = this.isReady;
      if (!visibilityIsReady) {
        visibilityIsReady = 0;
      } else {
        visibilityIsReady = 1;
      }
      if (this.form.typeTariffTemplate === 2) {
        let productPricingTemplate = false;
        await this.$refs.pricingTemplate
          .validateAndGetData()
          .then(async data => {
            productPricingTemplate = data;
            if (productPricingTemplate) {
              if (productPricingTemplate.productPricingTemplateId === 0) {
                await this.$refs.pricingTemplate.store(productPricingTemplate);
              } else {
                await this.$refs.pricingTemplate.save(productPricingTemplate);
              }
            }
          });
      }
      this.$refs.formRoom.storeForm(
        this.featuredUrl,
        this.startDate.typeStartDate,
        this.fees,
        visibilityIsReady
      );
    },
    async duplicate() {
      if (await this.$refs.StartDateTemplateForm.validate()) {
        this.$refs.formRoomInfo.storeForm();
        this.$refs.formBed.storeForm();

        const resultValidateMeal = await this.$refs.formMaintenanceRate.validateFields();
        if (!resultValidateMeal) {
          this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          return false;
        }
        this.$refs.formMaintenanceRate.storeForm();
        if (this.show.showApartment) {
          this.$refs.formApartment.storeForm();
        }
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
      this.$refs.formRoom.duplicateForm(
        this.featuredUrl,
        this.startDate.typeStartDate,
        this.fees,
        this.isReady
      );
    },
    deleteData() {
      this.$refs.formRoom.deleteRecord();
    },
    validateApartment(data) {
      if (data > 4 && data < 9) {
        this.show.showApartment = true;
      } else {
        this.show.showApartment = false;
      }
    },
    btnDelete() {
      this.isShowDialogDelete = true;
    },
    btnCancel() {
      this.$router.go(-1);
    },
    loadTranslations(data) {
      this.isEditRoom = true;
      const { name, description, productId } = data;
      const dataJson = [name, description];
      this.$refs.translationComponent.getTranslations(productId, dataJson);
    },
    async showStartDateTemplateForm(data) {
      this.startDate.typeStartDate = await this.SHOW_CUSTOM_START_DATE;
      this.$refs.StartDateTemplateForm.updateDataProp(data);
    },
    fetchData() {
      this.getRoom(this.$route.params.index).then(data => {
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
          },
          {
            title: `${this.$i18n.t("PRODUCTS_ADDONS.ACCOMMODATION")}`,
            route: "/lodging/edit/" + data.accommodationId,
          },
          {
            title: `${this.$i18n.t("PRODUCTS_ADDONS.EDIT")}`,
          },
        ]);
        this.dataGallery = data.photos;
        this.isReady = data.product.isReady;
        this.dataStartDateTemplate = data.start_date_template;
        this.galleryKey += 1;
        this.loadTranslations(data);
      });
    },
  },
  watch: {
    async room(newValue) {
      if (this.$route.params.operation === "edit" || this.isComponent) {
        const arrData = Vue.util.extend({}, newValue);
        this.dataGallery = arrData.photos ?? [];
        this.featuredUrl = arrData.featureUrl ?? "";
        this.isPublish = arrData.product.isPublish;
        if (arrData.photos) this.$refs.gallery.updatedGallery(arrData.photos);
        if (
          arrData.start_date_template &&
          arrData.start_date_template.productStartDateTemplateId !== 0
        ) {
          await this.showStartDateTemplateForm(arrData.start_date_template);
        }

        if (arrData.productPricingTemplateId !== 0) {
          this.form.typeTariffTemplate = 2;
          this.productPricingTemplateId = arrData.productPricingTemplateId;
        }

        if (arrData.product_fees && arrData.product_fees.length > 0) {
          this.fees = arrData.product_fees?.map(item => {
            return {
              id: item.feeId,
            };
          });
        }

        this.validateApartment(arrData.roomTypeId);
      }
    },
    listAllFees: {
      handler: function(val, oldVal) {
        if (oldVal.length && val.length > oldVal.length) {
          this.fees.push({
            id: this.fee.feeId,
          });
        }
      },
    },
    $route: "fetchData",
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0px !important;
}
.v-alert__content a {
  color: #d3d3d3;
}
</style>

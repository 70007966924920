<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isOnlyShow">
          <v-row gutters="2">
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.ROOM_SURFACE')"
                rules="numeric"
              >
                <v-text-field
                  v-model="form.roomSurface"
                  :label="$t('PRODUCTS_ADDONS.ROOM_SURFACE')"
                  outlined
                  dense
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.UNIT_ROOM_SURFACE')"
                rules="numeric"
              >
                <v-select
                  v-model="form.unitRoomSurface"
                  :items="unitsRoomSurface"
                  :label="$t('PRODUCTS_ADDONS.UNIT_ROOM_SURFACE')"
                  item-text="text"
                  required
                  item-value="id"
                  outlined
                  dense
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <div class="inline-element pr-5">
                <v-checkbox
                  v-model="form.basement"
                  :label="$t('PRODUCTS_ADDONS.BASEMENT')"
                  @click="form.basementWithWindow = false"
                  hide-details="auto"
                ></v-checkbox>
              </div>
              <div class="inline-element">
                <v-expand-transition>
                  <v-checkbox
                    v-model="form.basementWithWindow"
                    label="With window"
                    v-show="form.basement"
                    hide-details="auto"
                  ></v-checkbox>
                </v-expand-transition>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="4"
              v-for="(amenity, index) in amenityOne"
              :key="index"
            >
              <v-checkbox
                v-model="form.facilities"
                :label="amenity.name"
                :value="amenity.amenityTypeId"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row gutters="2">
            <v-col cols="12" md="12">
              <h4>Services included in pricing</h4>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="4"
              v-for="(service, index) in amenityTwo"
              :key="index"
            >
              <v-checkbox
                v-model="form.services"
                :label="service.name"
                :value="service.amenityTypeId"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      typeDuration: [
        { text: "Day", id: 2 },
        { text: "Week", id: 3 },
      ],
      unitsRoomSurface: [
        { text: "m.2", id: 1 },
        { text: "ft.2", id: 2 },
      ],
      form: {
        roomSurface: null,
        unitRoomSurface: null,
        basement: false,
        basementWithWindow: false,
        qtyRooms: null,
        maxStudents: null,
        facilities: [],
        services: [],
      },
      AMENITY_BASE: 4,
      AMENITY_SECONDARY: 5,
    };
  },
  computed: {
    ...mapGetters(["categories", "amenityTypes", "room"]),
    amenityOne() {
      const data = this.amenityTypes.filter(amenity => {
        return amenity.typeId === this.AMENITY_BASE;
      });

      return data;
    },
    amenityTwo() {
      const data = this.amenityTypes.filter(amenity => {
        return amenity.typeId === this.AMENITY_SECONDARY;
      });
      return data;
    },
  },
  methods: {
    ...mapActions(["storeRoomInfo"]),
    storeForm() {
      this.storeRoomInfo(this.form);
    },
  },
  watch: {
    room(newValue) {
      if (this.$route.params.operation === "edit" || this.isOnlyShow) {
        const roomInfoVal = Vue.util.extend({}, newValue);
        this.form.basement = roomInfoVal.basement;
        this.form.roomSurface = roomInfoVal.roomSurface;
        this.form.unitRoomSurface = roomInfoVal.unitRoomSurface;
        this.form.basementWithWindow = roomInfoVal.basementWithWindow;

        this.form.qtyRooms = roomInfoVal.qtyRooms;
        let arrType1 = [];
        let arrType2 = [];
        roomInfoVal.amenity?.map(item => {
          if (item.typeId === this.AMENITY_BASE) {
            arrType1.push(item.amenityTypeId);
          } else if (item.typeId === this.AMENITY_SECONDARY) {
            arrType2.push(item.amenityTypeId);
          }
        });

        this.form.maxStudents = newValue.maxStudents;
        this.form.facilities = arrType1;
        this.form.services = arrType2;
      }
    },
  },
};
</script>
<style scoped>
.inline-element {
  display: inline-block !important;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>

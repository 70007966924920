<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isOnlyShow">
          <v-row gutters="2" class="remove-bottom-space">
            <v-expand-transition>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Quantity rooms"
                  rules="numeric"
                >
                  <v-text-field
                    v-model="form.qtyRooms"
                    label="Quantity Rooms"
                    dense
                    type="number"
                    step="1"
                    outlined
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-expand-transition>
            <v-expand-transition>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Maximum students"
                  rules="numeric"
                >
                  <v-text-field
                    v-model="form.maxStudents"
                    label="Maximum Students"
                    dense
                    type="number"
                    outlined
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-expand-transition>
          </v-row>
          <v-expand-transition>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="4"
                v-for="(space, index) in amenityThree"
                :key="index"
                class="group-fields-bottom"
              >
                <v-checkbox
                  v-model="form.common"
                  :label="space.name"
                  :value="space.amenityTypeId"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      errors: [],
      form: {
        qtyRooms: null,
        maxStudents: null,
        aparment: false,
        common: [],
      },
      AMENITY_APARTMENT: 6,
    };
  },
  computed: {
    ...mapGetters(["amenityTypes", "room"]),
    amenityThree() {
      const data = this.amenityTypes.filter(amenity => {
        return amenity.typeId === this.AMENITY_APARTMENT;
      });
      return data;
    },
  },
  mounted() {
    if (this.$route.params.operation === "edit" || this.isOnlyShow) {
      const arrType3 = [];
      const apartment = Vue.util.extend({}, this.room);
      apartment.amenity?.map(item => {
        const amenity = item;
        if (amenity.typeId === this.AMENITY_APARTMENT) {
          arrType3.push(amenity.amenityTypeId);
        } else {
          return false;
        }
      });
      this.form.apartment = true;
      this.form.qtyRooms = apartment.qtyRooms;
      this.form.maxStudents = apartment.maxStudents;
      this.form.common = arrType3;
    }
  },
  methods: {
    ...mapActions(["storeApartment"]),
    storeForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.storeApartment(this.form);
          return true;
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    room(newValue, oldValue) {
      if (
        (this.$route.params.operation === "edit" || this.isOnlyShow) &&
        JSON.stringify(newValue) !== JSON.stringify(oldValue)
      ) {
        const arrType3 = [];
        const apartment = Vue.util.extend({}, newValue);
        apartment.amenity?.map(item => {
          const amenity = item;
          if (amenity.typeId === this.AMENITY_APARTMENT) {
            arrType3.push(amenity.amenityTypeId);
          } else {
            return false;
          }
        });
        this.form.apartment = true;
        this.form.qtyRooms = apartment.qtyRooms;
        this.form.maxStudents = apartment.maxStudents;
        this.form.common = arrType3;
      }
    },
  },
};
</script>
<style scoped>
.group-fields-bottom {
  margin-bottom: -30px !important;
}

.remove-bottom-space {
  margin-bottom: -10px !important;
}
</style>

<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isComponent">
          <v-row align="center" justify="end" class="pt-0 mt-0 pb-0 mb-0">
            <v-col cols="12" align="end">
              <a
                class="body-2"
                v-if="helpCategory === false"
                @click="helpCategory = true"
              >
                {{ $t("GENERAL.SHOW_HELP") }}
              </a>
              <a class="body-2" v-else @click="helpCategory = false">
                {{ $t("GENERAL.HIDE_HELP") }}
              </a>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0 pb-0 mb-0">
            <v-col cols="12">
              <v-alert
                v-model="helpCategory"
                type="info"
                border="left"
                elevation="2"
                colored-border
              >
                <strong>{{ $t("PRODUCTS_ADDONS.HELP_CATEGORY") }}:</strong>
                <br />
                {{ helpTextCategoryRoom }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.NAME')"
                rules="required"
              >
                <v-text-field
                  v-model="form.name"
                  :label="$t('PRODUCTS_ADDONS.NAME')"
                  outlined
                  required
                  :error-messages="errors"
                  dense
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.CATEGORY')"
                rules="required"
              >
                <v-select
                  v-model="form.roomTypeId"
                  :items="roomTypesFiltered"
                  :label="$t('PRODUCTS_ADDONS.CATEGORY')"
                  item-text="codeName"
                  required
                  item-value="roomTypeId"
                  @change="validateApartmentChange()"
                  outlined
                  dense
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                form.roomTypeId === SHARED_ENSUITE ||
                  form.roomTypeId === SHARED_BATHROOM
              "
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.QUANTITY_PERSONS')"
                rules="required|numeric|max_value:99|min_value:1"
              >
                <v-text-field
                  v-model="form.qtyStudent"
                  :label="$t('PRODUCTS_ADDONS.QUANTITY_PERSONS')"
                  outlined
                  type="number"
                  dense
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                rules="required|min:150|max:52000"
              >
                <v-textarea
                  v-model="form.description"
                  :label="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                  outlined
                  dense
                  :error-messages="errors"
                  required
                  counter="52000"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
    <ForceDelete
      typeProduct="ROOM"
      :validatePromotions="validatePromotions"
      :validatePackages="validatePackages"
      :productId="form.productId"
      :isShow="isShowDialogForceDelete"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </v-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";

export default {
  props: {
    gallery: {
      type: Array,
      default() {
        return [];
      },
    },
    startDateTemplate: {
      type: Object,
      default() {
        return {};
      },
    },
    isComponent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ForceDelete,
  },
  data() {
    return {
      errors: [],
      form: {
        productId: null,
        accommodationId: "",
        name: "",
        roomTypeId: null,
        description: "",
        qtyStudent: null,
        gallery: [],
        startDateTemplate: {},
        featureUrl: null,
      },
      SHARED_ENSUITE: 5,
      SHARED_BATHROOM: 6,
      validatePromotions: [],
      validatePackages: [],
      isShowDialogForceDelete: false,
      keyValidateDelete: 0,
      HOMETUITION: 4,
      FilterRoomTypeHometuition: [1, 2, 3, 4, 5, 6],
      helpCategory: true,
    };
  },
  computed: {
    ...mapGetters(["roomTypes", "accommodations", "campus", "room", "company"]),
    accommodationsFiltered() {
      return this.accommodations.filter(accommodation => {
        return this.campus.campusId === accommodation.campusId;
      });
    },
    roomTypesFiltered() {
      if (this.company && this.company.companyTypeId === this.HOMETUITION) {
        return this.roomTypes?.filter(item => {
          return this.FilterRoomTypeHometuition.indexOf(item.roomTypeId) > -1;
        });
      } else {
        return this.roomTypes;
      }
    },
    helpTextCategoryRoom() {
      switch (this.form.roomTypeId) {
        case 1:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_SINGLE_ENSUITE");
        case 2:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_SINGLE_SHARED_BATHROOM");
        case 3:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_DOUBLE_ENSUITE");
        case 4:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_DOUBLE_SHARED_BATHROOM");
        case 5:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_SHARED_ENSUITE");
        case 6:
          return this.$t("PRODUCTS_ADDONS.DESCRIPTION_SHARED_BATHROOM");
        case 7:
          return this.$t(
            "PRODUCTS_ADDONS.DESCRIPTION_SINGLE_ENSUITE_APARTMENT"
          );
        case 8:
          return this.$t(
            "PRODUCTS_ADDONS.DESCRIPTION_SINGLE_SHARED_BATHROOM_APARTMENT"
          );
        case 9:
          return this.$t(
            "PRODUCTS_ADDONS.DESCRIPTION_DOUBLE_ENSUITE_APARTMENT"
          );
        case 10:
          return this.$t(
            "PRODUCTS_ADDONS.DESCRIPTION_DOUBLE_SHARED_BATHROOM_APARTMENT"
          );
        default:
          return this.$t("PRODUCTS_ADDONS.DEFAULT_CATEGORY_DESCRIPTION");
      }
    },
  },
  mounted() {
    if (this.$route.params.operation === "edit") {
      const id = this.$route.params.index;
      this.form.productId = id;
    }
  },
  methods: {
    ...mapActions(["storeRoom", "deleteRoom", "updateRoom", "forceDeleteRoom"]),
    storeForm(featureUrl = "", typeStartDate = "", fees = [], isReady = false) {
      if (this.validateForm()) {
        this.form.featureUrl = featureUrl;
        this.form.typeStartDate = typeStartDate;
        this.form.isReady = isReady;
        this.form.fees = fees;
        if (this.$route.params.operation === "edit") {
          this.form.productId = this.$route.params.index;
          this.updateRoom(this.form).then(() => {
            this.$emit("loadTranslation", this.form);
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          });
        } else {
          this.storeRoom(this.form).then(response => {
            this.form.productId = response.productId;

            this.$router.push({
              name: "product-room-edit",
              params: {
                operation: "edit",
                index: response.productId,
              },
            });
            this.$emit("loadTranslation", this.form);
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.$store.dispatch("setBreadcrumb", [
              {
                title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
              },
              {
                title: `${this.$i18n.t("PRODUCTS_ADDONS.ROOM")}`,
                route: "/products/room",
              },
              {
                title: `${this.$i18n.t("PRODUCTS_ADDONS.EDIT")}`,
              },
            ]);
          });
        }
      } else {
        this.$bvToast.toast("Please verify the fields", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return false;
      }
    },
    deleteRecord() {
      this.deleteRoom(this.form.productId).then(data => {
        if (data.success) {
          this.$router.push({ name: "product-room-table" });
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion ? data.promotion : [];
          this.validatePackages = data.package ? data.package : [];
          this.isShowDialogForceDelete =
            this.validatePromotions.length || this.validatePackages.length;
          this.keyValidateDelete += 1;
        }
      });
    },
    forceDelete() {
      this.forceDeleteRoom(this.form.productId).then(() => {
        this.$router.push({ name: "product-room-table" });
        this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      });
    },
    duplicateForm(featureUrl = "", typeStartDate = "") {
      if (this.$route.params.operation === "edit") {
        this.$refs.observer.validate().then(data => {
          if (data) {
            this.form.featureUrl = featureUrl;
            this.form.typeStartDate = typeStartDate;
            this.form.name = this.form.name + " (Copy)";
            delete this.form.productId;
            this.storeRoom(this.form).then(response => {
              this.form.productId = response.productId;
              this.$emit("loadTranslation", this.form);
              this.$router.push({
                name: "product-room-edit",
                params: {
                  operation: "edit",
                  index: response.productId,
                },
              });
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
            });
          } else {
            this.$bvToast.toast("Please verify the fields", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
            return false;
          }
        });
      }
    },
    validateApartmentChange() {
      this.$emit("validate-category", this.form.roomTypeId);
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
    async validateForm() {
      return await this.$refs.observer.validate();
    },
  },
  watch: {
    room(newValue) {
      if (this.$route.params.operation === "edit" || this.isComponent) {
        this.form.accommodationId = newValue.accommodationId;
        this.form.name = newValue.name;
        this.form.roomTypeId = newValue.roomTypeId;
        this.form.description = newValue.description;
        this.form.qtyStudent = newValue.qtyStudent;
      }
    },
    gallery(newValue) {
      this.form.gallery = newValue;
    },
    startDateTemplate(newValue) {
      this.form.startDateTemplate = newValue;
    },
  },
};
</script>
<style scoped>
.v-list-item {
  min-height: 24px;
}
</style>

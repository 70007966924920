<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isOnlyShow">
          <div v-for="(bed, index) in beds" :key="index">
            <v-row gutters="2">
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('PRODUCTS_ADDONS.WIDTH')"
                  rules="required"
                >
                  <v-text-field
                    v-model="beds[index].width"
                    outlined
                    dense
                    type="number"
                    :label="$t('PRODUCTS_ADDONS.WIDTH')"
                    hide-details="auto"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('PRODUCTS_ADDONS.LENGTH')"
                  rules="required"
                >
                  <v-text-field
                    v-model="beds[index].length"
                    outlined
                    dense
                    type="number"
                    :label="$t('PRODUCTS_ADDONS.LENGTH')"
                    hide-details="auto"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" :md="isOnlyShow ? 3 : 2">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('PRODUCTS_ADDONS.UNITY')"
                  rules="required"
                >
                  <v-select
                    v-model="beds[index].sizeTypeId"
                    outlined
                    dense
                    :label="$t('PRODUCTS_ADDONS.UNITY')"
                    :items="units"
                    item-value="id"
                    hide-details="auto"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="1" v-if="!isOnlyShow">
                <v-icon v-on:click="removeBed(index)" class="mt-2"
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters v-if="!isOnlyShow">
            <v-col cols="12" md="12">
              <v-btn depressed color="primary" @click="addBed">Add bed</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      beds: [],
      typeBeds: [
        { text: "Double", id: "1" },
        { text: "Individual", id: "2" },
      ],
      units: [
        { text: "cm", id: "1" },
        { text: "in", id: "2" },
      ],
    };
  },
  computed: {
    ...mapGetters(["categories", "amenityTypes", "room"]),
  },
  methods: {
    ...mapActions(["storeBed"]),
    storeForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.storeBed(this.beds);
          return true;
        } else {
          return false;
        }
      });
    },
    addBed() {
      const newBedTemplate = {
        width: "",
        length: "",
        sizeTypeId: "",
      };
      this.beds.push(newBedTemplate);
    },
    removeBed(index) {
      this.beds.splice(index, 1);
    },
  },
  watch: {
    room(newValue) {
      if (this.$route.params.operation === "edit" || this.isOnlyShow) {
        const arrBeds = [];
        const beds = Vue.util.extend([], newValue.bed);
        beds?.map(item => {
          arrBeds.push({
            width: item.width,
            length: item.length,
            sizeTypeId: item.sizeTypeId + "",
          });
        });
        this.beds = arrBeds;
      }
    },
  },
};
</script>
<style scoped>
.remove-bottom-space {
  margin-bottom: -10px !important;
}
</style>

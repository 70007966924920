<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isOnlyShow">
          <div v-for="(meal, mealIndex) in meals" :key="mealIndex">
            <v-row gutters="2" class="mb-0 pb-0">
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Meal type"
                  rules="required"
                >
                  <v-select
                    v-model="meals[mealIndex].roomMealTypeId"
                    outlined
                    dense
                    item-text="codeName"
                    item-value="mealTypeId"
                    label="Meal category"
                    :items="mealTypesUpdate"
                    hide-details="auto"
                    :error-messages="errors"
                    required
                    @change="
                      meals[mealIndex].roomMealTypeId === TYPE_NO_MEALS
                        ? (meals[mealIndex].amount = 0)
                        : (meals[mealIndex].amount = null)
                    "
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Amount"
                  rules="required"
                >
                  <v-text-field
                    v-model="meals[mealIndex].amount"
                    outlined
                    dense
                    :label="$t('PRODUCTS_ADDONS.AMOUNT_WEEK')"
                    type="number"
                    hide-details="auto"
                    :error-messages="errors"
                    required
                    :disabled="
                      meals[mealIndex].roomMealTypeId === TYPE_NO_MEALS
                    "
                    :key="`AMOUNT-${index}`"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="1" v-if="!isOnlyShow">
                <v-icon v-on:click="removeMeal(mealIndex)" class="mt-2">
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
            <v-row
              gutters="2"
              class="mb-0 pb-0"
              v-for="(diet, index) in meals[mealIndex].diet"
              :key="index"
            >
              <v-col cols="12" md="3" offset="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Diet type"
                  rules="required"
                >
                  <v-select
                    v-model="meals[mealIndex].diet[index].dietId"
                    outlined
                    dense
                    item-value="dietTypeId"
                    item-text="name"
                    label="Diet category"
                    :items="dietTypes"
                    hide-details="auto"
                    :error-messages="errors"
                    required
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Amount"
                  rules="required"
                >
                  <v-text-field
                    v-model="meals[mealIndex].diet[index].amount"
                    outlined
                    dense
                    type="number"
                    label="Amount"
                    hide-details="auto"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="1" v-if="!isOnlyShow">
                <v-icon v-on:click="removeDiet(mealIndex, index)" class="mt-2">
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>

            <v-row gutters="2" v-if="!isOnlyShow">
              <v-col cols="12" md="9" offset="3">
                <v-btn depressed color="primary" @click="addDiet(mealIndex)">
                  Add Diet
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
          <v-row no-gutters v-if="!isOnlyShow">
            <v-col cols="12" md="12">
              <v-btn depressed color="primary" @click="addMeal">
                Add Meal
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

import Vue from "vue";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      meals: [],
      mealType: [],
      TYPE_NO_MEALS: 1,
    };
  },
  computed: {
    ...mapGetters([
      "categories",
      "amenityTypes",
      "mealTypes",
      "dietTypes",
      "maintenanceRates",
      "room",
    ]),
    mealTypesUpdate: function() {
      return this.mealTypes?.map(item => {
        item.codeName = this.$i18n.t(`PRODUCTS_ADDONS.${item.code}`);
        return item;
      });
    },
  },
  methods: {
    ...mapActions(["storeMaintenanceRates"]),
    storeForm() {
      this.storeMaintenanceRates(this.meals);
    },
    async addMeal() {
      const resultValidate = await this.validateFields();
      if (resultValidate) {
        const newMealTemplate = {
          roomMealId: null,
          name: "",
          roomMealTypeId: "",
          amount: "",
          diet: [
            {
              roomMealDietId: null,
              dietId: "",
              amount: "",
            },
          ],
        };
        this.meals.push(newMealTemplate);
      }
    },
    async addDiet(mealIndex) {
      const resultValidate = await this.validateFields();
      if (resultValidate) {
        const newDietTemplate = {
          roomMealDietId: null,
          dietId: "",
          amount: "",
        };
        this.meals[mealIndex].diet.push(newDietTemplate);
      }
    },
    removeMeal(index) {
      this.meals.splice(index, 1);
    },
    removeDiet(mealIndex, index) {
      this.meals[mealIndex].diet.splice(index, 1);
    },
    async validateFields() {
      return await this.$refs.observer.validate();
    },
  },
  watch: {
    room(newValue) {
      if (this.$route.params.operation === "edit" || this.isOnlyShow) {
        const meal = Vue.util.extend([], newValue.meal);
        this.meals = meal?.map(item => {
          const arrDiet = [];
          item.diet?.map(diet => {
            arrDiet.push({
              roomMealDietId: diet.roomMealDietId + "",
              amount: diet.amount + "",
              dietId: diet.dietId,
            });
          });
          return {
            roomMealId: item.roomMealId,
            roomMealTypeId: item.roomMealTypeId,
            diet: arrDiet,
            amount: item.amount,
            name: item.name,
          };
        });
      }
    },
  },
};
</script>
